//src/api/data.js
import axios from 'axios';
import FormData from 'form-data';

// Define the function to fetch data from the API
export const fetchData = async () => {
  // Initialize form data
  const data = new FormData();
  data.append('username', 'product');
  data.append('key', 'bqNZ4ADMKxqubPdh42LXTV7yuFJ7UYYkivLs7LBNpJ5DniDKHgnbRogPjhaLLRBJMuKUtSA1EvotW4AN50u4PWbZcQxEPvaBX6oTArjkYnizqEe3hLlHOYthtAJ4dNKRpGQMBJ33DMIe17X7FtMoSmMsYSOliKp5ST4LAzIzgRFwMQvNzn3JsMpaigcP7Y6wv2WCiaMHQ4kxTAguF3b1MX84ANA1IcyxdBJDNf256uKgSKYrLqHfLWSR2rH2s8EE');

  // Define the configuration for the axios request
  const config = {
    method: 'post',
    url: 'https://thaifly.com/index.php?route=cms/productlanding&category_id=34',
    headers: { 
      ...data.getHeaders(), // Use `getHeaders` method from `form-data`
      'Cookie': 'OCSESSID=5377a265defb069b8bb5bd4c67; currency=THB'
    },
    data: data
  };

  try {
    // Make the request using axios
    const response = await axios.request(config);
    return response.data;  // Return the response data
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;  // Re-throw error to be handled by the caller
  }
};
