import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom'; // Updated import

const Home = () => {
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const [activeTab, setActiveTab] = useState('tab1');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const carouselRef = useRef(null);
  const messages = ["ยินดีต้อนรับสู่", "บริษัททัวร์ที่ดีที่สุดของคุณ"];
  const [displayText, setDisplayText] = useState("");
  const [index, setIndex] = useState(0);
  const [charIndex, setCharIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [delay, setDelay] = useState(200);
  const [pause, setPause] = useState(false);
  const [showCursor, setShowCursor] = useState(true);
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const navigate = useNavigate(); // Use useNavigate instead of useHistory
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedPrice, setSelectedPrice] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    const query = {
      search: searchTerm,
      month: selectedMonth,
      priceRange: selectedPrice,
    };
    // Remove empty values
    const queryString = new URLSearchParams(
      Object.fromEntries(Object.entries(query).filter(([_, v]) => v))
    ).toString();
    // Navigate to search page with query parameters
    navigate(`/search?${queryString}`); // Use navigate
  };

  const handleScroll = () => {
    if (window.scrollY > 300) {
      setShowScrollToTop(true);
    } else {
      setShowScrollToTop(false);
    }
  };

  // Add event listener for scroll
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Function to scroll to top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const cursorTimer = setInterval(() => {
      setShowCursor((prev) => !prev);
    }, 500);

    return () => clearInterval(cursorTimer);
  }, []);

  useEffect(() => {
    if (pause) return;

    if (charIndex < messages[index].length && !isDeleting) {
      const timer = setTimeout(() => {
        setDisplayText((prev) => prev + messages[index][charIndex]);
        setCharIndex(charIndex + 1);
      }, delay);
      return () => clearTimeout(timer);
    } else if (isDeleting && charIndex > 0) {
      const timer = setTimeout(() => {
        setDisplayText((prev) => prev.slice(0, -1));
        setCharIndex(charIndex - 1);
      }, delay / 2);
      return () => clearTimeout(timer);
    } else if (charIndex === messages[index].length && !isDeleting) {
      setPause(true);
      setTimeout(() => {
        setIsDeleting(true);
        setPause(false);
      }, 3000);
    } else if (charIndex === 0 && isDeleting) {
      setIsDeleting(false);
      setIndex((prev) => (prev + 1) % messages.length);
    }
  }, [charIndex, isDeleting, pause]);

  useEffect(() => {
    if (carouselRef.current) {
      setTotalItems(carouselRef.current.children.length);
    }
  }, [carouselRef.current]);

  const scrollCarousel = (direction) => {
    const itemWidth = carouselRef.current.children[0].offsetWidth;
    const totalItemsCount = totalItems;
    let newIndex = currentIndex + direction;

    // Wrap around logic
    if (newIndex < 0) {
      newIndex = totalItemsCount - 4;
    } else if (newIndex > totalItemsCount - 4) {
      newIndex = 0;
    }

    setCurrentIndex(newIndex);
    carouselRef.current.style.transform = `translateX(-${newIndex * itemWidth}px)`;
  };

  const handleIndicatorClick = (index) => {
    const itemWidth = carouselRef.current.children[0].offsetWidth;
    const newIndex = index * 4;
    setCurrentIndex(newIndex);
    carouselRef.current.style.transform = `translateX(-${newIndex * itemWidth}px)`;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      scrollCarousel(1);
    }, 3000);
    return () => clearInterval(interval);
  }, [currentIndex, totalItems]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsImageLoaded(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );

    const target = document.querySelector('#background-container');
    if (target) {
      observer.observe(target);
    }

    return () => {
      if (target) {
        observer.unobserve(target);
      }
    };
  }, []);

  return (
    <>

    <div
      id="background-container"
      className="relative min-h-screen bg-cover bg-center transition-all duration-500"
style={{
backgroundImage:  "url('/images/center.jpg')"}}

    >
        {/* Main content */}
        <div className="container mx-auto px-4 pt-16">
      <div className="w-full max-w-4xl mx-auto mt-16 mb-20">
        <div className="sticky top-16 bg-transparent p-8 rounded-lg text-white">
          <h2 className="text-3xl font-bold mb-4">
            {displayText}
            {showCursor && <span className="cursor">|</span>} {/* แสดงเคอร์เซอร์ */}
            <br />
            <span className="font-bold text-blue-500">Thaifly</span> Travel
          </h2>

          <form onSubmit={handleSubmit} className="mb-4">
            <div className="flex flex-wrap gap-4 mb-4" style={{fontSize: '12px'}}>
              <div className="flex-1 min-w-[200px]">
                <label htmlFor="search-country" className="block text-white font-medium mb-1" >
                  ค้นหาประเทศ,เมือง,สถานที่,รหัสทัวร์
                </label>
                <input
                  id="search-country"
                  name="searchTerm"
                  type="text"
                  placeholder=""
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="w-full p-2 border text-black rounded-md"
                />
              </div>
              <div className="flex-1 min-w-[200px]">
                <label htmlFor="select-month" className="block text-white font-medium mb-1">
                  เลือกเดือน
                </label>
                <select
                  id="select-month"
                  name="selectedMonth"
                  value={selectedMonth}
                  onChange={(e) => setSelectedMonth(e.target.value)}
                  className="w-full p-2 border text-black rounded-md"
                >
                  <option value="">-- เลือกเดือน --</option>
                  <option value="01">มกราคม</option>
              <option value="02">กุมภาพันธ์</option>
              <option value="03">มีนาคม</option>
              <option value="04">เมษายน</option>
              <option value="05">พฤษภาคม</option>
              <option value="06">มิถุนายน</option>
              <option value="07">กรกฎาคม</option>
              <option value="08">สิงหาคม</option>
              <option value="09">กันยายน</option>
              <option value="10">ตุลาคม</option>
              <option value="11">พฤศจิกายน</option>
              <option value="12">ธันวาคม</option>
                </select>
              </div>

              <div className="flex-1 min-w-[200px]">
                <label htmlFor="select-price" className="block text-white font-medium mb-1">
                  ค้นหา ช่วงราคา
                </label>
                <select
                  id="select-price"
                  name="selectedPrice"
                  value={selectedPrice}
                  onChange={(e) => setSelectedPrice(e.target.value)}
                  className="w-full p-2 border text-black rounded-md"
                >
                  <option value="888888888">-- ทุกช่วงราคา --</option>
                  <option value="0-10000">฿0 - ฿10,000</option>
              <option value="10000-20000">฿10,000 - ฿20,000</option>
              <option value="20000-30000">฿20,000 - ฿30,000</option>
              <option value="30000-40000">฿30,000 - ฿40,000</option>
              <option value="40000-50000">฿40,000 - ฿50,000</option>
              <option value="50000-60000">฿50,000 - ฿60,000</option>
              <option value="60000-70000">฿60,000 - ฿70,000</option>
              <option value="70000-80000">฿70,000 - ฿80,000</option>
              <option value="80000-90000">฿80,000 - ฿90,000</option>
              <option value="90000+">฿90,000+</option>
                </select>
              </div>

              <div className="flex items-end">
                <button type="submit" className="bg-blue-700 hover:bg-blue-700 text-white py-2 px-4 
  rounded thin-text flex items-center justify-center space-x-2 ml-auto">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
</svg>

            
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

        {/* Card */}
        <div className="absolute inset-x-0 top-1/1 transform -translate-y-1/1 p-6 bg-white rounded-t-[50px]  z-10">


        <div className="flex justify-center">
  <h2 className="text-4xl font-bold mb-4 text-center">
    จอง <span className="text-blue-500">ตั๋วเครื่องบิน</span>
  </h2>
  
</div>
<div className="flex justify-center" style={{marginBottom:'20px'}}>

<p>ตั๋วเครื่องบินโปรโมชั่น</p>

  
</div>

<div className="relative w-full max-w-full mx-auto">
        <div className="carousel-container overflow-hidden rounded-lg relative">
          <div
            className="carousel-wrapper flex transition-transform duration-500 ease-in-out"
            ref={carouselRef}
          >
            {/* Carousel items */}
            <div className="carousel-item flex-shrink-0 w-1/4">
              <img src="/images/pro-cn.jpg" alt="Image 1" className="w-80 h-auto rounded-lg" />
            </div>
            <div className="carousel-item flex-shrink-0 w-1/4">
              <img src="/images/pro-italy.jpg" alt="Image 2" className="w-80 h-auto rounded-lg" />
            </div>
            <div className="carousel-item flex-shrink-0 w-1/4">
              <img src="/images/new-pro.jpg" alt="Image 3" className="w-80 h-auto rounded-lg" />
            </div>
            <div className="carousel-item flex-shrink-0 w-1/4">
              <img src="/images/pro-aug-hk.jpg" alt="Image 4" className="w-80 h-auto rounded-lg" />
            </div>
            {/* Duplicate items for infinite scroll effect */}
            <div className="carousel-item flex-shrink-0 w-1/4">
              <img src="/images/pro-cn.jpg" alt="Image 1" className="w-80 h-auto rounded-lg" />
            </div>
            <div className="carousel-item flex-shrink-0 w-1/4">
              <img src="/images/pro-italy.jpg" alt="Image 2" className="w-80 h-auto rounded-lg" />
            </div>
            <div className="carousel-item flex-shrink-0 w-1/4">
              <img src="/images/new-pro.jpg" alt="Image 3" className="w-80 h-auto rounded-lg" />
            </div>
            <div className="carousel-item flex-shrink-0 w-1/4">
              <img src="/images/pro-aug-hk.jpg" alt="Image 4" className="w-80 h-auto rounded-lg" />
            </div>

            {/* Duplicate items for infinite scroll effect */}
            <div className="carousel-item flex-shrink-0 w-1/4">
              <img src="/images/pro-cn.jpg" alt="Image 1" className="w-80 h-auto rounded-lg" />
            </div>
            <div className="carousel-item flex-shrink-0 w-1/4">
              <img src="/images/pro-italy.jpg" alt="Image 2" className="w-80 h-auto rounded-lg" />
            </div>
            <div className="carousel-item flex-shrink-0 w-1/4">
              <img src="/images/new-pro.jpg" alt="Image 3" className="w-80 h-auto rounded-lg" />
            </div>
            <div className="carousel-item flex-shrink-0 w-1/4">
              <img src="/images/pro-aug-hk.jpg" alt="Image 4" className="w-80 h-auto rounded-lg" />
            </div>


          </div>

          {/* Navigation buttons */}
          <button
            className="absolute top-1/2 left-2 transform -translate-y-1/2 bg-blue-500 text-white p-2 rounded-full shadow-lg hover:bg-blue-600"
            onClick={() => scrollCarousel(-1)}
          >
            &#10094;
          </button>
          <button
            className="absolute top-1/2 right-2 transform -translate-y-1/2 bg-blue-500 text-white p-2 rounded-full shadow-lg hover:bg-blue-600"
            onClick={() => scrollCarousel(1)}
          >
            &#10095;
          </button>




        </div>

       {/* Carousel indicators */}
       <div
          className="absolute left-1/2 transform -translate-x-1/2 flex space-x-2"
          style={{ bottom: '-1.2rem' }} // Adjust this value as needed
        >
          {Array.from({ length: Math.ceil(totalItems / 4) }).map((_, index) => (
            <div
              key={index}
              className={`w-3 h-3 rounded-full cursor-pointer ${index === Math.floor(currentIndex / 4) ? 'bg-blue-500' : 'bg-gray-300'}`}
              onClick={() => handleIndicatorClick(index)}
            />
          ))}
        </div>


      </div>

          <div className="p-4" style={{marginTop:'30px'}}>
            <h3 className="text-xl thin-text">เส้นทางยอดนิยม</h3>
            <p className="mt-2 text-gray-600">ทัวร์มาใหม่ ราคาถูกที่สุด ในจุดหมายปลายทางยอดนิยม</p>
          </div>

          <div className="border-b border-gray-200 mb-4">
          <div className="flex flex-wrap justify-start space-x-2 py-4">
  
              <button
                onClick={() => setActiveTab('tab1')}
                className={`flex items-center space-x-2 py-2 px-4 text-center thin-text rounded-md transition-all duration-300 ${activeTab === 'tab1' ? 'bg-blue-600 text-white border-b-2 border-blue-600' : 'bg-gray-200 text-gray-600 hover:bg-blue-100'}`}
              style={{marginBottom:'5px'}}
              >
                <img src="/images/japan.png" alt="Icon 1" className="w-5 h-5" />
                <span className="hidden md:inline">ทัวร์ญี่ปุ่น</span>
              </button>
              <button
                onClick={() => setActiveTab('tab2')}
                className={`flex items-center space-x-2 py-2 px-4 text-center thin-text rounded-md transition-all duration-300 ${activeTab === 'tab2' ? 'bg-blue-600 text-white border-b-2 border-blue-600' : 'bg-gray-200 text-gray-600 hover:bg-blue-100'}`}
                style={{marginBottom:'5px'}}
           >
                <img src="/images/china.png" alt="Icon 2" className="w-5 h-5" />
                <span className="hidden md:inline">ทัวร์จีน</span>
              </button>
              <button
                onClick={() => setActiveTab('tab3')}
                className={`flex items-center space-x-2 py-2 px-4 text-center thin-text rounded-md transition-all duration-300 ${activeTab === 'tab3' ? 'bg-blue-600 text-white border-b-2 border-blue-600' : 'bg-gray-200 text-gray-600 hover:bg-blue-100'}`}
                style={{marginBottom:'5px'}}
             >
                <img src="/images/vietnam.png" alt="Icon 3" className="w-5 h-5" />
                <span className="hidden md:inline">ทัวร์เวียดนาม</span>
              </button>

              <button
                onClick={() => setActiveTab('tab4')}
                className={`flex items-center space-x-2 py-2 px-4 text-center thin-text rounded-md transition-all duration-300 ${activeTab === 'tab4' ? 'bg-blue-600 text-white border-b-2 border-blue-600' : 'bg-gray-200 text-gray-600 hover:bg-blue-100'}`}
                style={{marginBottom:'5px'}}
              >
                <img src="/images/taiwan.png" alt="Icon 3" className="w-5 h-5" />
                <span className="hidden md:inline">ทัวร์ใต้หวัน</span>
              </button>

              <button
                onClick={() => setActiveTab('tab5')}
                className={`flex items-center space-x-2 py-2 px-4 text-center thin-text rounded-md transition-all duration-300 ${activeTab === 'tab5' ? 'bg-blue-600 text-white border-b-2 border-blue-600' : 'bg-gray-200 text-gray-600 hover:bg-blue-100'}`}
                style={{marginBottom:'5px'}}
             >
                <img src="/images/Europe.png" alt="Icon 3" className="w-5 h-5" />
                <span className="hidden md:inline">ทัวร์ยุโรป</span>
              </button>

              <button
                onClick={() => setActiveTab('tab6')}
                className={`flex items-center space-x-2 py-2 px-4 text-center thin-text rounded-md transition-all duration-300 ${activeTab === 'tab6' ? 'bg-blue-600 text-white border-b-2 border-blue-600' : 'bg-gray-200 text-gray-600 hover:bg-blue-100'}`}
                style={{marginBottom:'5px'}}
              >
                <img src="/images/korea.png" alt="Icon 3" className="w-5 h-5" />
                <span className="hidden md:inline">ทัวร์เกาหลี</span>
              </button>

              <button
                onClick={() => setActiveTab('tab7')}
                className={`flex items-center space-x-2 py-2 px-4 text-center thin-text rounded-md transition-all duration-300 ${activeTab === 'tab7' ? 'bg-blue-600 text-white border-b-2 border-blue-600' : 'bg-gray-200 text-gray-600 hover:bg-blue-100'}`}
                style={{marginBottom:'5px'}}
              >
                <img src="/images/hong-kong.png" alt="Icon 3" className="w-5 h-5" />
                <span className="hidden md:inline">ทัวร์ฮ่องกง</span>
              </button>


              <button
                onClick={() => setActiveTab('tab8')}
                className={`flex items-center space-x-2 py-2 px-4 text-center thin-text rounded-md transition-all duration-300 ${activeTab === 'tab8' ? 'bg-blue-600 text-white border-b-2 border-blue-600' : 'bg-gray-200 text-gray-600 hover:bg-blue-100'}`}
                style={{marginBottom:'5px'}}
              >
                <img src="/images/singapore.png" alt="Icon 3" className="w-5 h-5" />
                <span className="hidden md:inline">ทัวร์สิงคโปร์</span>
              </button>

              <button
                onClick={() => setActiveTab('tab9')}
                className={`flex items-center space-x-2 py-2 px-4 text-center thin-text rounded-md transition-all duration-300 ${activeTab === 'tab9' ? 'bg-blue-600 text-white border-b-2 border-blue-600' : 'bg-gray-200 text-gray-600 hover:bg-blue-100'}`}
                style={{marginBottom:'5px'}}
              >
                <img src="/images/india.png" alt="Icon 3" className="w-5 h-5" />
                <span className="hidden md:inline">ทัวร์อินเดีย</span>
              </button> 
              
              <button
                onClick={() => setActiveTab('tab10')}
                className={`flex items-center space-x-2 py-2 px-4 text-center thin-text rounded-md transition-all duration-300 ${activeTab === 'tab10' ? 'bg-blue-600 text-white border-b-2 border-blue-600' : 'bg-gray-200 text-gray-600 hover:bg-blue-100'}`}
                style={{marginBottom:'5px'}}
              >
                <img src="/images/china.png" alt="Icon 3" className="w-5 h-5" />
                <span className="hidden md:inline">ทัวร์กวางเจาเทรดแฟร์</span>
              </button>

            </div>
          </div>

          {/* Tab content */}
          <div className="p-4">
          {activeTab === 'tab1' && (
  <div>
    <h3 className="text-xl font-bold mb-4">ทัวร์ญี่ปุ่น แนะนำ</h3>
    {/* Card Grid */}
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mt-6">
{/* Card 1 */}
<div className="flex bg-white rounded-lg shadow-md overflow-hidden">
  <img src="/images/TOURTHFY616107.jpg" alt="Sample Image 1" className="w-1/3 h-auto object-cover" style={{ height: 'auto', minHeight: '128px' }} />
  <div className="p-4 flex-1 flex flex-col justify-between">
    <h4 className="text-lg font-semibold">รหัสทัวร์ THFY616107</h4>
    <p className=" text-gray-600">XJ226 ทัวร์ญี่ปุ่น โอซาก้า โกเบ เกียวโต (ซุปตาร์ โอซาก้า โกเบ โอเค นะคะ นะคะ) [DEC24-FEB25] 5วัน 3คืน บิน AIR ASIA X</p>
    
    <p className="mb-0 text-base my-2 hidden sm:flex items-center">
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-calendar4-week" viewBox="0 0 16 16">
    <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M2 2a1 1 0 0 0-1 1v1h14V3a1 1 0 0 0-1-1zm13 3H1v9a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1z"></path>
    <path d="M11 7.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm-2 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5z"></path>
  </svg>
  <span className="ml-2">ระยะเวลา 5 วัน 3 คืน</span>
</p>

<p className="mb-0 text-base my-2 hidden sm:flex items-center">
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-airplane" viewBox="0 0 16 16">
    <path d="M6.428 1.151C6.708.591 7.213 0 8 0s1.292.592 1.572 1.151C9.861 1.73 10 2.431 10 3v3.691l5.17 2.585a1.5 1.5 0 0 1 .83 1.342V12a.5.5 0 0 1-.582.493l-5.507-.918-.375 2.253 1.318 1.318A.5.5 0 0 1 10.5 16h-5a.5.5 0 0 1-.354-.854l1.319-1.318-.376-2.253-5.507.918A.5.5 0 0 1 0 12v-1.382a1.5 1.5 0 0 1 .83-1.342L6 6.691V3c0-.568.14-1.271.428-1.849m.894.448C7.111 2.02 7 2.569 7 3v4a.5.5 0 0 1-.276.447l-5.448 2.724a.5.5 0 0 0-.276.447v.792l5.418-.903a.5.5 0 0 1 .575.41l.5 3a.5.5 0 0 1-.14.437L6.708 15h2.586l-.647-.646a.5.5 0 0 1-.14-.436l.5-3a.5.5 0 0 1 .576-.411L15 11.41v-.792a.5.5 0 0 0-.276-.447L9.276 7.447A.5.5 0 0 1 9 7V3c0-.432-.11-.979-.322-1.401C8.458 1.159 8.213 1 8 1s-.458.158-.678.599"></path>
  </svg>
  <span className="ml-2">สายการบิน XJ</span>
</p>




    <div className="text-base mt-3 leading-tight">
  <span className="mr-2">
    เริ่มต้น 
    <span className="text-blue-500 text-xl">
       ฿35,888
    </span>
  </span>
</div>

  </div>
</div>

      {/* Card 2 */}
      <div className="flex bg-white rounded-lg shadow-md overflow-hidden">
  <img src="/images/TOURTHFY616105.jpg" alt="Sample Image 1" className="w-1/3 h-auto object-cover" 
  style={{ height: 'auto', minHeight: '128px' }} />
  <div className="p-4 flex-1 flex flex-col justify-between">
    <h4 className="text-lg font-semibold">รหัสทัวร์  THFY616105</h4>
    <p className=" text-gray-600">
    XJ225 ทัวร์ญี่ปุ่น โตเกียว (ซุปตาร์ โตเกียวปีใหม่ ไฟกระพริบ ปิ๊บ ปิ๊บ) [DEC] 6วัน 4คืน บิน AIR ASIA X </p>
    
    <p className="mb-0 text-base my-2 hidden sm:flex items-center">
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-calendar4-week" viewBox="0 0 16 16">
    <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M2 2a1 1 0 0 0-1 1v1h14V3a1 1 0 0 0-1-1zm13 3H1v9a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1z"></path>
    <path d="M11 7.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm-2 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5z"></path>
  </svg>
  <span className="ml-2"> ระยะเวลา  6 วัน  4 คืน </span>
</p>

<p className="mb-0 text-base my-2 hidden sm:flex items-center">
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-airplane" viewBox="0 0 16 16">
    <path d="M6.428 1.151C6.708.591 7.213 0 8 0s1.292.592 1.572 1.151C9.861 1.73 10 2.431 10 3v3.691l5.17 2.585a1.5 1.5 0 0 1 .83 1.342V12a.5.5 0 0 1-.582.493l-5.507-.918-.375 2.253 1.318 1.318A.5.5 0 0 1 10.5 16h-5a.5.5 0 0 1-.354-.854l1.319-1.318-.376-2.253-5.507.918A.5.5 0 0 1 0 12v-1.382a1.5 1.5 0 0 1 .83-1.342L6 6.691V3c0-.568.14-1.271.428-1.849m.894.448C7.111 2.02 7 2.569 7 3v4a.5.5 0 0 1-.276.447l-5.448 2.724a.5.5 0 0 0-.276.447v.792l5.418-.903a.5.5 0 0 1 .575.41l.5 3a.5.5 0 0 1-.14.437L6.708 15h2.586l-.647-.646a.5.5 0 0 1-.14-.436l.5-3a.5.5 0 0 1 .576-.411L15 11.41v-.792a.5.5 0 0 0-.276-.447L9.276 7.447A.5.5 0 0 1 9 7V3c0-.432-.11-.979-.322-1.401C8.458 1.159 8.213 1 8 1s-.458.158-.678.599"></path>
  </svg>
  <span className="ml-2">สายการบิน XJ</span>
</p>




    <div className="text-base mt-3 leading-tight">
  <span className="mr-2">
    เริ่มต้น 
    <span className="text-blue-500 text-xl">
    ฿48,888
    </span>
  </span>
</div>

  </div>
</div>

    </div>
  </div>
)}

            {activeTab === 'tab2' && (
              <div>
                <h3 className="text-xl font-bold">เนื้อหาแท็บ 2</h3>
                <p className="mt-2 text-gray-600">รายละเอียดของเนื้อหาแท็บ 2...</p>
              </div>
            )}
            {activeTab === 'tab3' && (
              <div>
                <h3 className="text-xl font-bold">เนื้อหาแท็บ 3</h3>
                <p className="mt-2 text-gray-600">รายละเอียดของเนื้อหาแท็บ 3...</p>
              </div>
            )}

            {activeTab === 'tab4' && (
              <div>
                <h3 className="text-xl font-bold">เนื้อหาแท็บ 4</h3>
                <p className="mt-2 text-gray-600">รายละเอียดของเนื้อหาแท็บ 4...</p>
              </div>
            )}

            
            {activeTab === 'tab5' && (
              <div>
                <h3 className="text-xl font-bold">เนื้อหาแท็บ 5</h3>
                <p className="mt-2 text-gray-600">รายละเอียดของเนื้อหาแท็บ 5...</p>
              </div>
            )}

            {activeTab === 'tab6' && (
              <div>
                <h3 className="text-xl font-bold">เนื้อหาแท็บ 6</h3>
                <p className="mt-2 text-gray-600">รายละเอียดของเนื้อหาแท็บ 6...</p>
              </div>
            )}

            {activeTab === 'tab7' && (
              <div>
                <h3 className="text-xl font-bold">เนื้อหาแท็บ 7</h3>
                <p className="mt-2 text-gray-600">รายละเอียดของเนื้อหาแท็บ 7...</p>
              </div>
            )}

           {activeTab === 'tab8' && (
              <div>
                <h3 className="text-xl font-bold">เนื้อหาแท็บ 8</h3>
                <p className="mt-2 text-gray-600">รายละเอียดของเนื้อหาแท็บ 8...</p>
              </div>
            )}

           {activeTab === 'tab9' && (
              <div>
                <h3 className="text-xl font-bold">เนื้อหาแท็บ 9</h3>
                <p className="mt-2 text-gray-600">รายละเอียดของเนื้อหาแท็บ 9...</p>
              </div>
            )}
            {activeTab === 'tab10' && (
              <div>
                <h3 className="text-xl font-bold">เนื้อหาแท็บ 10</h3>
                <p className="mt-2 text-gray-600">รายละเอียดของเนื้อหาแท็บ 10...</p>
              </div>
            )}




          </div>







        </div>

                  {/* Scroll to Top Button */}
                  {showScrollToTop && (
        <button
          onClick={scrollToTop}
          className="fixed bottom-4 right-4 bg-blue-800 text-white p-4 rounded-full shadow-lg hover:bg-blue-600 transition-all duration-300 z-50"
          style={{ position: 'fixed' }}
        >
          ↑
        </button>
      )}

      </div>

    </>
  );
};

export default Home;
