import React, { useState, useEffect } from 'react';
import { fetchData } from '../../api/data';  // Adjust path if necessary

const View = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      try {
        const result = await fetchData();
        setData(result);  // Set fetched data
      } catch (err) {
        setError(err);  // Set error if fetch fails
      } finally {
        setLoading(false);  // Set loading to false
      }
    };

    loadData();  // Fetch data on mount
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div>
      <h1>View Page</h1>
      <pre>{JSON.stringify(data, null, 2)}</pre>  {/* Display JSON data */}
    </div>
  );
};

export default View;
