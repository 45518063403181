import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import Image from 'react-bootstrap/Image'; // Adjust if using a different image library

const Navbar = () => {
    const [open, setOpen] = useState(false); // State for the first dropdown
    const [openSecond, setOpenSecond] = useState(false); // State for the second dropdown
    const [isScrolled, setIsScrolled] = useState(false);
    const menuRef = useRef(null); // Ref for the first dropdown
    const menuRefSecond = useRef(null); // Ref for the second dropdown

    const toggleMenu = () => {
        setOpen(!open);
        setOpenSecond(false); // Close the second dropdown when the first one opens
    };

    const toggleSecondMenu = () => {
        setOpenSecond(!openSecond);
        setOpen(false); // Close the first dropdown when the second one opens
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setOpen(false); // Close the first dropdown when clicking outside
            }
            if (menuRefSecond.current && !menuRefSecond.current.contains(event.target)) {
                setOpenSecond(false); // Close the second dropdown when clicking outside
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [menuRef, menuRefSecond]);

    const closeDropdowns = () => {
        setOpen(false);
        setOpenSecond(false);
    };

    return (
        <>
        <nav
            className={`rounded-b-3xl fixed top-0 w-full z-50 transition duration-300 ${
                isScrolled ? 'bg-blue-800 text-white' : 'bg-transparent text-black-800'
            } h-16`}
        >
            <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
                <div className="relative flex items-center justify-between h-16">
                    <div className="flex-shrink-0">
                        <Link href="/" className="flex items-center">
                            <Image src="/images/tf-logo.png" width={500} height={500} alt="Logo" className="h-12 w-auto" />
                        </Link>
                    </div>
                    
                    {/* Desktop Menu */}
                    <div className="hidden lg:flex lg:items-center lg:justify-center lg:space-x-4 flex-1">

                        <div className="relative" ref={menuRef}>
                        <button
        className={`text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium flex items-center`}
        onClick={toggleMenu}
    >
        ทัวร์ต่างประเทศ
        <svg
    className={`w-4 h-4 ml-2 transition-transform duration-300 ${
      open ? "rotate-180" : "rotate-0"
    }`}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
>
    <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3} 
        d="M19 9l-7 7-7-7"
    />
</svg>

    </button>

    {open && (
  <div
  className="justify-center absolute w-max max-w-6xl bg-white shadow-lg py-6 px-8 
  grid grid-cols-3 md:grid-cols-3 gap-3 text-gray-800 z-20 rounded-lg"
  style={{
    minWidth: '1200px', 
    right: '0%', 
    top: '4rem',
    transform: 'translateX(70%)', 
  }}
>
    {/* Asia Column */}


    <div className="flex flex-col border-r border-gray-300 pr-2">
      <h3 className="font-bold text-lg mb-2">ทวีปเอเซีย</h3>
      <ul className="grid grid-cols-2 gap-3">
        <li>
          <Link href="/outbound/japan" className="hover:text-gray-500 flex items-center" onClick={closeDropdowns}>
            <img src="/images/japan.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์ญี่ปุ่น
          </Link>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center" onClick={closeDropdowns}>
            <img src="/images/china.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์จีน
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/vietnam.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์เวียดนาม
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/taiwan.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์ใต้หวัน
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/korea.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์เกาหลี
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/singapore.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์สิงคโปร์
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/hong-kong.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์ฮ่องกง
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/macao.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์มาเก๊า
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/malaysia.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์มาเลเซีย
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/china.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์กวางเจาเทรดแฟร์
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/india.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์อินเดีย
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/myanmar.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์พม่า
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/laos.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์ลาว
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/bhutan.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์ภูฏาน
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/indonesia.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์บาหลี
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/Emirates.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์ดูไบ
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/jordan.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์จอร์แดน
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/egypt.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์อียิปต์
          </a>
        </li>
      </ul>
    </div>


    {/* Europe Column */}
    <div className="flex flex-col border-r border-gray-300 pr-2">
      <h3 className="font-bold text-lg mb-2">ทวีปยุโรป</h3>
      <ul className="grid grid-cols-2 gap-3">
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/Europe.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์ยุโรป
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/switzerland.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์สวิตเซอร์แลนด์
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/france.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์ฝรั่งเศส
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/italy.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์อิตาลี
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/england.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์อังกฤษ
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/spain.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์สเปน
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/germany.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์เยอรมัน
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/netherlands.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์เนเธอร์แลนด์
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/portugal.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์โปรตุเกส
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/scandinavian.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์สแกนดิเนเวีย
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/denmark.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์เดนมาร์ก
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/austria.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์ออสเตรีย
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/norway.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์นอร์เวย์
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/iceland.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์ไอซ์แลนด์
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/sweden.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์สวีเดน
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/russia.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์รัสเซีย
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/Turkiye.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์ตุรกี
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/georgia.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์จอร์เจีย
          </a>
        </li>
      </ul>
    </div>


    {/* Category 3 Column */}
    <div className="flex flex-col flex-shrink-0 w-1/1">
      <h3 className="font-bold text-lg mb-2">ทวีปอื่นๆ</h3>
      <ul className="grid grid-cols-1 gap-3">
      <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/Europe.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์ต่างประเทศ
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/united-states-of-america.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์อเมริกา
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/australia-1.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์ออสเตรเลีย
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/new-zealand.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์นิวซีแลนด์
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/canada.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์แคนาดา
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/Africa.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์แอฟริกา
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/Europe.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์คาซัคสถาน
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/thailand.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์ในประเทศ
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/hotdeal.png" alt="Icon" className="w-4 h-4 mr-2" />
            โปรไฟไหม้
          </a>
        </li>
      </ul>
    </div>


 
  </div>
)}

                        </div>
                        <a href="#" className="text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium">จองตั๋วเครื่องบิน</a>
               {/* Dropdown */}
               <div className="relative" ref={menuRefSecond}>
                        <button
                            className="text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium flex items-center"
                            onClick={toggleSecondMenu}
                        >
                            รับจัดทัวร์
                            <svg
                                className={`w-4 h-4 ml-2 transition-transform duration-300 ${
                                    openSecond ? "rotate-180" : "rotate-0"
                                }`}
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={3}
                                    d="M19 9l-7 7-7-7"
                                />
                            </svg>
                        </button>

                        {openSecond && (
                            <div
                                className="justify-center absolute w-max max-w-6xl bg-white shadow-lg py-6 px-8 
                                grid grid-cols-3 md:grid-cols-3 gap-3 text-gray-800 z-20 rounded-lg"
                                style={{
                                  minWidth: '1000px', 
                                  right: '0%', 
                                  top: '4rem',
                                  transform: 'translateX(48%)', 
                                }}
                            >
                                {/* Dropdown contents */}
                                <div className="flex flex-col border-r border-gray-300 pr-2">
                                    <h3 className="font-bold text-lg mb-2">ทวีปเอเซีย</h3>
                                    <ul className="grid grid-cols-2 gap-3">
                                        <li>
                                            <a href="#" className="hover:text-gray-500 flex items-center">
                                                <img src="/images/japan.png" alt="Icon" className="w-4 h-4 mr-2" />
                                                ทัวร์ญี่ปุ่น
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" className="hover:text-gray-500 flex items-center">
                                                <img src="/images/china.png" alt="Icon" className="w-4 h-4 mr-2" />
                                                ทัวร์จีน
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" className="hover:text-gray-500 flex items-center">
                                                <img src="/images/vietnam.png" alt="Icon" className="w-4 h-4 mr-2" />
                                                ทัวร์เวียดนาม
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" className="hover:text-gray-500 flex items-center">
                                                <img src="/images/taiwan.png" alt="Icon" className="w-4 h-4 mr-2" />
                                                ทัวร์ใต้หวัน
                                            </a>
                                        </li>
                                    </ul>
                                </div>

                                {/* Europe Column */}
                                <div className="flex flex-col border-r border-gray-300 pr-2">
                                    <h3 className="font-bold text-lg mb-2">ทวีปยุโรป</h3>
                                    <ul className="grid grid-cols-2 gap-3">
                                        <li>
                                            <a href="#" className="hover:text-gray-500 flex items-center">
                                                <img src="/images/Europe.png" alt="Icon" className="w-4 h-4 mr-2" />
                                                ทัวร์ยุโรป
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" className="hover:text-gray-500 flex items-center">
                                                <img src="/images/switzerland.png" alt="Icon" className="w-4 h-4 mr-2" />
                                                ทัวร์สวิตเซอร์แลนด์
                                            </a>
                                        </li>
                                    </ul>
                                </div>

                                {/* Other Regions Column */}
                                <div className="flex flex-col flex-shrink-0 w-1/1">
                                    <h3 className="font-bold text-lg mb-2">ทวีปอื่นๆ</h3>
                                    <ul className="grid grid-cols-1 gap-3">
                                        <li>
                                            <a href="#" className="hover:text-gray-500 flex items-center">
                                                <img src="/images/Europe.png" alt="Icon" className="w-4 h-4 mr-2" />
                                                ทัวร์ต่างประเทศ
                                            </a>
                                        </li>
                                        {/* Add other list items */}
                                    </ul>
                                </div>
                            </div>
                        )}
                           </div>
                           <a href="#" className="text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium">ทีมบิ้วดิ้ง</a>
                           <a href="#" className="text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium">เกี่ยวกับเรา</a>
                           <a href="#" className="text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium">ข้อมูลท่องเที่ยว</a>

                        <button className="thin-text bg-green-500 text-white font-semibold py-1 px-3 text-sm rounded hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400">
    ติดต่อเรา
</button>
                    </div>

                    {/* Hamburger Menu */}
                    <div className="lg:hidden flex items-center">
                        <button
                            className="text-gray-300 hover:text-white focus:outline-none"
                            onClick={toggleMenu}
                        >
                            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>

            {/* Mobile Menu */}
            <div className={`lg:hidden ${open ? 'block' : 'hidden'} bg-white text-black rounded-xl`}>
                <div className="flex flex-col items-center overflow-y-auto max-h-[600px]">
                    <a href="#" className="block px-3 py-2 text-sm font-medium">Home</a>
                    <button
                        className="text-gray-300 hover:text-black w-full text-left px-3 py-2 rounded-md text-sm font-medium"
                        onClick={toggleMenu}
                    >
                        Mega Menu
                    </button>

                    {open && (
                        <div className="bg-white text-black py-6 px-8 w-full" >
                            <div>
                            <h3 className="font-bold text-lg mb-2">ทวีปเอเซีย</h3>
      <ul className="grid grid-cols-2 gap-3" style={{marginBottom: '20px'}}>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/japan.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์ญี่ปุ่น
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/china.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์จีน
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/vietnam.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์เวียดนาม
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/taiwan.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์ใต้หวัน
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/korea.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์เกาหลี
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/singapore.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์สิงคโปร์
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/hong-kong.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์ฮ่องกง
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/macao.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์มาเก๊า
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/malaysia.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์มาเลเซีย
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/china.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์กวางเจาเทรดแฟร์
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/india.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์อินเดีย
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/myanmar.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์พม่า
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/laos.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์ลาว
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/bhutan.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์ภูฏาน
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/indonesia.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์บาหลี
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/Emirates.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์ดูไบ
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/jordan.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์จอร์แดน
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/egypt.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์อียิปต์
          </a>
        </li>
      </ul>
                            </div>
                            <div>
                            <h3 className="font-bold text-lg mb-2">ทวีปยุโรป</h3>
      <ul className="grid grid-cols-2 gap-3" style={{marginBottom: '20px'}}>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/Europe.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์ยุโรป
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/switzerland.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์สวิตเซอร์แลนด์
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/france.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์ฝรั่งเศส
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/italy.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์อิตาลี
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/england.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์อังกฤษ
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/spain.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์สเปน
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/germany.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์เยอรมัน
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/netherlands.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์เนเธอร์แลนด์
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/portugal.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์โปรตุเกส
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/scandinavian.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์สแกนดิเนเวีย
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/denmark.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์เดนมาร์ก
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/austria.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์ออสเตรีย
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/norway.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์นอร์เวย์
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/iceland.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์ไอซ์แลนด์
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/sweden.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์สวีเดน
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/russia.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์รัสเซีย
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/Turkiye.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์ตุรกี
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/georgia.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์จอร์เจีย
          </a>
        </li>
      </ul>
                            </div>
                            <div>
                            <h3 className="font-bold text-lg mb-2">ทวีปอื่นๆ</h3>
      <ul className="grid grid-cols-1 gap-3" style={{marginBottom: '20px'}}>
      <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/Europe.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์ต่างประเทศ
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/united-states-of-america.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์อเมริกา
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/australia-1.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์ออสเตรเลีย
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/new-zealand.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์นิวซีแลนด์
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/canada.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์แคนาดา
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/Africa.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์แอฟริกา
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/Europe.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์คาซัคสถาน
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/thailand.png" alt="Icon" className="w-4 h-4 mr-2" />
            ทัวร์ในประเทศ
          </a>
        </li>
        <li>
          <a href="#" className="hover:text-gray-500 flex items-center">
            <img src="/images/hotdeal.png" alt="Icon" className="w-4 h-4 mr-2" />
            โปรไฟไหม้
          </a>
        </li>
      </ul>
                            </div>

                        </div>
                    )}
                    <a href="#" className="block px-3 py-2 text-sm font-medium">About</a>
                    <a href="#" className="block px-3 py-2 text-sm font-medium">Contact</a>

                    <button className="bg-green-500 text-white font-semibold py-1 px-3 text-sm rounded hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400">
    Small Button
</button>
                </div>
            </div>
        </nav>


        </>
    );
};

export default Navbar;
