import React from 'react';
import { createRoot } from 'react-dom/client'; // Import createRoot
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import App from './App';
import Home from './page/home/home';
import View from './page/api/view';
import Navbar from './components/Navbar';
import './index.css';
import '@fontsource/prompt';

// Create a root
const root = createRoot(document.getElementById('root'));

// Render the application
root.render(
  <React.StrictMode>
    <Router>
      <Navbar />
      <Routes>
        {/* Route for the root path */}
        <Route path="/" element={<Home />} />
        <Route path="/api/view" element={<View/>} />
        {/* Any other routes */}
      </Routes>
    </Router>
  </React.StrictMode>
);
